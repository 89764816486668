import React, { useState } from 'react';
import { useLogin, useNotify } from 'react-admin';
import { Card, Box } from '@mui/material';
import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';

import Logo from './logo.component';
import LoginForm, { LoginFormProps } from './login-form.component';
import NewPasswordForm, { NewPasswordFormProps } from './new-password-form.component';

const Login: React.FC = () => {
  const [newPasswordUser, setNewPasswordUser] = useState<CognitoUser | null>(null);

  const login = useLogin();

  const notify = useNotify();

  const handleError = (error: unknown) => {
    notify(error instanceof Error ? error.message : 'Unknown error', { type: 'error' });
    setNewPasswordUser(null);
  };

  const onLoginSubmit: LoginFormProps['onSubmit'] = (email, password) => {
    Auth.signIn(email, password)
      .then((result) => (result.challengeName === 'NEW_PASSWORD_REQUIRED' ? setNewPasswordUser : login)(result))
      .catch(handleError);
  };

  const onNewPasswordSubmit: NewPasswordFormProps['onSubmit'] = async (newPassword) =>
    Auth.completeNewPassword(newPasswordUser, newPassword).then(login).catch(handleError);

  return (
    <Box sx={styles.container}>
      <Card sx={styles.card}>
        <Logo />
        {newPasswordUser ? <NewPasswordForm onSubmit={onNewPasswordSubmit} /> : <LoginForm onSubmit={onLoginSubmit} />}
      </Card>
    </Box>
  );
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    background: 'radial-gradient(circle at 50% 50%, #F09D99 0%, #F97D77 60%, #F95047 100%)',
  },
  card: { display: 'flex', flexDirection: 'column', gap: 3, padding: '3rem 4rem' },
};

export default Login;
