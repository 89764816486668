import React from 'react';
import { useParams } from 'react-router-dom';
import { Datagrid, DateField, List as RAList, TextField } from 'react-admin';
import { Card } from '@mui/material';
import { ResourceType } from '../../types';
import EmptyList from '../../components/empty-list.component';

const Empty = () => (
  <Card style={{ width: '100%' }}>
    <EmptyList text="No favorites for this user" />
  </Card>
);

const List: React.FC<{ userId?: string }> = ({ userId: propsUserId }) => {
  const { userId } = useParams();

  return (
    <RAList
      resource={ResourceType.Favorites}
      filter={{ userId: userId || propsUserId }}
      exporter={false}
      empty={<Empty />}
      actions={false}
      pagination={false}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" sortable={false} />
        <TextField source="itemKey" sortable={false} />
        <DateField source="createdAt" transform={(value: number) => new Date(Number(`${value}000`))} showTime sortable={false} />
      </Datagrid>
    </RAList>
  );
};

export default List;
