import { ResourceProps } from 'react-admin';
import PeopleIcon from '@mui/icons-material/People';

import list from './list';
import show from './show';
import { ResourceType } from '../../types';

export const users: ResourceProps = {
  name: ResourceType.Users,
  list,
  show,
  options: { label: 'User management' },
  icon: PeopleIcon,
};
