import { CognitoAccessToken } from 'amazon-cognito-identity-js';
import { GroupType } from '../types';

const getGroupMap = () => ({
  [GroupType.Admin]: process.env.REACT_APP_ADMIN_GROUP_NAME,
  [GroupType.Employee]: process.env.REACT_APP_LB_EMPLOYEE_GROUP_NAME,
  [GroupType.TechEmployee]: process.env.REACT_APP_LB_TECH_EMPLOYEE_GROUP_NAME,
});

export const getUserGroups = ({ payload }: CognitoAccessToken) => {
  const groups = payload['cognito:groups'] ?? [];
  return Object.entries(getGroupMap()).reduce(
    (current, [key, value]) => ({
      ...current,
      [key]: groups.includes(value),
    }),
    {} as Record<GroupType, boolean>,
  );
};

export const hasAdminGroup = (token: CognitoAccessToken) => getUserGroups(token)[GroupType.Admin];
