const awsconfig = {
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID,
  aws_cloud_logic_custom: [
    {
      name: 'AdminQueries',
      // eslint-disable-next-line max-len
      endpoint: `https://${process.env.REACT_APP_AWS_ADMIN_QUERIES_PREFIX}.execute-api.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${process.env.REACT_APP_AWS_API_ENV}`,
      region: process.env.REACT_APP_AWS_REGION,
    },
  ],
};

export default awsconfig;
