import { DataProvider } from 'react-admin';
import { appSyncClient } from '../app-sync-client';
import { GetLearningRecommendationDocument, GetLearningRecommendationQuery } from '../../core/appsync/generated/graphql';

// @ts-ignore We do not need all provider methods for mocked provider ("Partial<T>" will cause a type error)
export const learningRecommendationsDataProvider: DataProvider = {
  getOne: async (_, { id: userId }): Promise<any> => {
    const payload = {
      query: GetLearningRecommendationDocument,
      variables: {
        userId,
      },
    };

    return appSyncClient
      .query<GetLearningRecommendationQuery>(payload)
      .then((result) => ({
        data: result.data?.adminLearningRecommendation,
      }))
      .catch((error) => {
        console.error(error);
        return {
          data: null,
        };
      });
  },
};
