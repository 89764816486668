import { SimpleShowLayout, TextField, DateField, NumberField, ArrayField, Datagrid } from 'react-admin';
import { Card } from '@mui/material';
import EmptyList from '../../../components/empty-list.component';
import { Workout as WorkoutModel } from '../../../core/appsync/generated/graphql';

export const Workout = ({ record }: { record: WorkoutModel }) => {
  if (!record?.id) {
    return (
      <Card>
        <EmptyList text="No workout for this user" />
      </Card>
    );
  }

  return (
    <Card>
      <SimpleShowLayout record={record}>
        <TextField source="id" />
        <DateField source="createdAt" showTime />
        <DateField source="weekStart" showTime />
        <NumberField source="weaklyGoalSeconds" />
        <ArrayField source="assessmentResponses">
          <Datagrid bulkActionButtons={false} sx={{ maxWidth: '800px' }}>
            <TextField source="assessmentType" />
            <TextField source="mobilityLevel" />
          </Datagrid>
        </ArrayField>

        <ArrayField source="assignments">
          <Datagrid bulkActionButtons={false}>
            <TextField source="order" />
            <TextField source="assessmentType" />
            <TextField source="mobilityLevel" />
            <TextField source="exerciseId" />
            <TextField source="id" />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Card>
  );
};
