import { combineDataProviders } from 'react-admin';
import {
  favoritesDataProvider,
  learningAssessmentResponsesDataProvider,
  learningRecommendationsDataProvider,
  learningProgressDataProvider,
  mockDataProvider,
  usersDataProvider,
  workoutsDataProvider,
  assignmentProgressDataProvider,
} from './data-providers';

import './app-sync-client';
import { ResourceType } from '../types';

// @ts-ignore We do not need all provider methods for mocked provider ("Partial<T>" will cause a type error)
export const dataProvider = combineDataProviders((resource) => {
  switch (resource) {
    case ResourceType.Users:
      return usersDataProvider;

    case ResourceType.Favorites:
      return favoritesDataProvider;

    case ResourceType.LearningAssessmentResponses:
      return learningAssessmentResponsesDataProvider;

    case ResourceType.LearningRecommendations:
      return learningRecommendationsDataProvider;

    case ResourceType.LearningProgress:
      return learningProgressDataProvider;

    case ResourceType.Workouts:
      return workoutsDataProvider;

    case ResourceType.AssignmentProgress:
      return assignmentProgressDataProvider;

    default:
      return mockDataProvider;
  }
});
