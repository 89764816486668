import { SimpleShowLayout, TextField, DateField } from 'react-admin';
import { Card } from '@mui/material';
import EmptyList from '../../../components/empty-list.component';
import { LearningRecommendation as LearningRecommendationModel } from '../../../core/appsync/generated/graphql';

export const LearningRecommendation = ({ record }: { record: LearningRecommendationModel }) => {
  if (!record?.id) {
    return (
      <Card>
        <EmptyList text="No learning recommendation for this user" />
      </Card>
    );
  }

  return (
    <Card>
      <SimpleShowLayout record={record}>
        <TextField source="id" />
        <TextField source="userId" />
        <TextField source="responseId" />
        <TextField source="recommendedCourseId" />
        <DateField source="createdAt" showTime />
      </SimpleShowLayout>
    </Card>
  );
};
