import { DataProvider, GetListResult } from 'react-admin';
import { appSyncClient } from '../app-sync-client';
import { GetFavoritesDocument, GetFavoritesQuery } from '../../core/appsync/generated/graphql';

// @ts-ignore We do not need all provider methods for mocked provider ("Partial<T>" will cause a type error)
export const favoritesDataProvider: DataProvider = {
  getList: async (_, { filter }): Promise<GetListResult> => {
    const payload = {
      query: GetFavoritesDocument,
      variables: {
        userId: filter.userId,
      },
    };

    return appSyncClient
      .query<GetFavoritesQuery>(payload)
      .then((result) => ({
        data: result.data?.adminFavorites?.items,
        total: result.data?.adminFavorites?.items.length,
      }))
      .catch((error) => {
        console.error(error);
        return error;
      });
  },
};
