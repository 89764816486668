import { Card } from '@mui/material';
import { SimpleShowLayout, TextField, NumberField, DateField, ArrayField, Datagrid } from 'react-admin';
import EmptyList from '../../../components/empty-list.component';
import { LearningAssessmentResponse as LearningAssessmentResponseModel } from '../../../core/appsync/generated/graphql';

export const LearningAssessmentResponse = ({ record }: { record: LearningAssessmentResponseModel }) => {
  if (!record?.id) {
    return (
      <Card>
        <EmptyList text="No learning assessment response for this user" />
      </Card>
    );
  }

  return (
    <Card>
      <SimpleShowLayout record={record}>
        <TextField source="id" />
        <TextField source="userId" />
        <NumberField source="hpsScore" />
        <DateField source="createdAt" showTime />
        <DateField source="validUntil" showTime />
        <ArrayField source="answers">
          <Datagrid bulkActionButtons={false} sx={{ maxWidth: '600px' }}>
            <TextField source="questionKey" />
            <NumberField source="score" />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Card>
  );
};
