import { DataProvider, GetListResult } from 'react-admin';
import { defaultMock } from '../../mocks';

// @ts-ignore We do not need all provider methods for mocked provider ("Partial<T>" will cause a type error)
export const mockDataProvider: DataProvider = {
  getList: async (_, { pagination }): Promise<GetListResult> => {
    const count = (pagination.page - 1) * pagination.perPage;

    return {
      data: defaultMock.slice(count, count + pagination.perPage),
      total: defaultMock.length,
    };
  },
};
