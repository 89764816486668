import { DataProvider, GetListResult, SortPayload } from 'react-admin';
import { appSyncClient } from '../app-sync-client';
import { AssignmentProgress, GetAssignmentProgressDocument, GetAssignmentProgressQuery } from '../../core/appsync/generated/graphql';

type LatestResult = {
  userId: string;
  data: AssignmentProgress[];
};

let latestResult: LatestResult | null = null;

// @ts-ignore We do not need all provider methods for mocked provider ("Partial<T>" will cause a type error)
export const assignmentProgressDataProvider: DataProvider = {
  getList: async (_, { filter }): Promise<GetListResult> => {
    if (latestResult && latestResult.userId === filter.userId && filter.sort) {
      const sort = filter.sort as SortPayload;
      return {
        data: latestResult.data
          .slice()
          .sort((a: any, b: any) => String(a[sort.field]).localeCompare(String(b[sort.field])) * (filter.sort.order === 'ASC' ? 1 : -1)),
        total: latestResult.data.length,
      };
    }

    const payload = {
      query: GetAssignmentProgressDocument,
      variables: {
        userId: filter.userId,
      },
    };

    return appSyncClient
      .query<GetAssignmentProgressQuery>(payload)
      .then((response) => {
        const result = {
          data: response.data?.adminAssignmentProgress?.items,
          total: response.data?.adminAssignmentProgress?.items.length,
        };

        latestResult = {
          userId: filter.userId,
          data: result.data,
        };

        return result;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
  },
};
