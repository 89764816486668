import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Long: { input: number; output: number; }
};

export type Query = {
  __typename?: 'Query';
  activeCourse?: Maybe<ActiveCourse>;
  adminActiveCourse?: Maybe<ActiveCourse>;
  adminAllLearningProgress: LearningProgressPayload;
  adminAssignmentProgress: QueryAssignmentProgressPayload;
  adminFavorites: FavoritesPayload;
  adminLearningAssessmentResponse?: Maybe<LearningAssessmentResponse>;
  adminLearningProgress?: Maybe<LearningProgressPayload>;
  adminLearningRecommendation?: Maybe<LearningRecommendation>;
  adminMoodTrackerCheckIn: MoodTrackerCheckInPayload;
  adminMoodTrackerOptions: Options;
  adminWorkout?: Maybe<Workout>;
  allLearningProgress: LearningProgressPayload;
  assignmentProgressHistory: GetAssignmentProgressHistoryPayload;
  assignmentProgressReports: AssignmentProgressReportsPayload;
  courseLessonsTasks: CourseLessonsTasks;
  courses: Courses;
  favorites: FavoritesPayload;
  learningAssessmentResponse?: Maybe<LearningAssessmentResponse>;
  learningCourses: LearningCourses;
  learningProgress: LearningProgressPayload;
  learningQuestionnaire: LearningQuestionnaire;
  learningRecommendation?: Maybe<LearningRecommendation>;
  learningSelfAssessmentQuestions: LearningSelfAssessmentQuestions;
  moodTrackerCheckIn: MoodTrackerCheckInPayload;
  moodTrackerOptions: Options;
  questionnaires: Questionnaires;
  selfAssessmentQuestions: SelfAssessmentQuestions;
  workout?: Maybe<Workout>;
};


export type QueryAdminActiveCourseArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryAdminAllLearningProgressArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryAdminAssignmentProgressArgs = {
  startTime: Scalars['Int']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryAdminFavoritesArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryAdminLearningAssessmentResponseArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryAdminLearningProgressArgs = {
  learningProgressInput: LearningProgressInput;
  userId: Scalars['ID']['input'];
};


export type QueryAdminLearningRecommendationArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryAdminMoodTrackerCheckInArgs = {
  moodTrackerCheckInInput: MoodTrackerCheckInInput;
  userId: Scalars['ID']['input'];
};


export type QueryAdminMoodTrackerOptionsArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryAdminWorkoutArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryAssignmentProgressHistoryArgs = {
  startTime: Scalars['Int']['input'];
  timestamps: Array<Scalars['Int']['input']>;
};


export type QueryAssignmentProgressReportsArgs = {
  startDate: Scalars['String']['input'];
};


export type QueryCourseLessonsTasksArgs = {
  courseId?: InputMaybe<Scalars['String']['input']>;
  type: LearningType;
};


export type QueryCoursesArgs = {
  type: LearningType;
};


export type QueryLearningProgressArgs = {
  learningProgressInput: LearningProgressInput;
};


export type QueryLearningQuestionnaireArgs = {
  taskKey: Scalars['String']['input'];
};


export type QueryMoodTrackerCheckInArgs = {
  moodTrackerCheckInInput: MoodTrackerCheckInInput;
};


export type QueryQuestionnairesArgs = {
  type: LearningType;
};


export type QuerySelfAssessmentQuestionsArgs = {
  type: LearningType;
};

export type ActiveCourse = {
  __typename?: 'ActiveCourse';
  courseId: Scalars['String']['output'];
  createdAt: Scalars['Long']['output'];
  id: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type LearningProgressPayload = {
  __typename?: 'LearningProgressPayload';
  items: Array<Maybe<LearningProgress>>;
};

export type LearningProgress = {
  __typename?: 'LearningProgress';
  createdAt: Scalars['Long']['output'];
  id: Scalars['String']['output'];
  questionnaireResponses?: Maybe<Array<Maybe<QuestionnaireResponses>>>;
  referenceItemId: Scalars['String']['output'];
  referenceItemType: LearningReferenceItemType;
  taskId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type QuestionnaireResponses = {
  __typename?: 'QuestionnaireResponses';
  answerKeys: Array<Scalars['String']['output']>;
  questionKey: Scalars['String']['output'];
};

export enum LearningReferenceItemType {
  CatalogueOutro = 'CATALOGUE_OUTRO',
  Content = 'CONTENT',
  Questionnaire = 'QUESTIONNAIRE'
}

export type QueryAssignmentProgressPayload = {
  __typename?: 'QueryAssignmentProgressPayload';
  items: Array<AssignmentProgress>;
};

export type AssignmentProgress = {
  __typename?: 'AssignmentProgress';
  assignmentId: Scalars['ID']['output'];
  createdAt: Scalars['Long']['output'];
  durationSeconds: Scalars['Int']['output'];
  exerciseId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  sessionId: Scalars['ID']['output'];
  workoutId: Scalars['ID']['output'];
};

export type FavoritesPayload = {
  __typename?: 'FavoritesPayload';
  items: Array<Favorite>;
};

export type Favorite = {
  __typename?: 'Favorite';
  createdAt: Scalars['Long']['output'];
  id: Scalars['String']['output'];
  itemKey: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type LearningAssessmentResponse = {
  __typename?: 'LearningAssessmentResponse';
  answers: Array<LearningAssessmentAnswer>;
  createdAt: Scalars['Long']['output'];
  hpsScore: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  userId: Scalars['String']['output'];
  validUntil: Scalars['Long']['output'];
};

export type LearningAssessmentAnswer = {
  __typename?: 'LearningAssessmentAnswer';
  questionKey: Scalars['String']['output'];
  score: Scalars['Int']['output'];
};

export type LearningProgressInput = {
  taskId: Scalars['String']['input'];
};

export type LearningRecommendation = {
  __typename?: 'LearningRecommendation';
  createdAt: Scalars['Long']['output'];
  id: Scalars['String']['output'];
  recommendedCourseId: Scalars['String']['output'];
  responseId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type MoodTrackerCheckInInput = {
  endDate: Scalars['Int']['input'];
  startDate: Scalars['Int']['input'];
};

export type MoodTrackerCheckInPayload = {
  __typename?: 'MoodTrackerCheckInPayload';
  items: Array<MoodTrackerCheckIn>;
};

export type MoodTrackerCheckIn = {
  __typename?: 'MoodTrackerCheckIn';
  createdAt: Scalars['Long']['output'];
  feelings: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  rating: Scalars['Int']['output'];
  reasons: Array<Scalars['String']['output']>;
  recommendations?: Maybe<Array<Scalars['String']['output']>>;
};

export type Options = {
  __typename?: 'Options';
  feelings: Array<Array<Scalars['String']['output']>>;
  reasons: Array<Scalars['String']['output']>;
};

export type Workout = {
  __typename?: 'Workout';
  assessmentResponses: Array<AssessmentResponse>;
  assignments: Array<Assignment>;
  createdAt: Scalars['Long']['output'];
  id: Scalars['String']['output'];
  validUntil: Scalars['Long']['output'];
  weaklyGoalSeconds: Scalars['Long']['output'];
  weekStart: Scalars['Long']['output'];
};

export type AssessmentResponse = {
  __typename?: 'AssessmentResponse';
  assessmentType: AssessmentType;
  mobilityLevel: MobilityLevel;
};

export enum AssessmentType {
  ApronHandle = 'APRON_HANDLE',
  NeckGrip = 'NECK_GRIP',
  Rest = 'REST',
  Torso = 'TORSO'
}

export enum MobilityLevel {
  Level_1 = 'LEVEL_1',
  Level_2 = 'LEVEL_2',
  Level_3 = 'LEVEL_3',
  Level_4 = 'LEVEL_4',
  None = 'NONE'
}

export type Assignment = {
  __typename?: 'Assignment';
  assessmentType: AssessmentType;
  exerciseId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  mobilityLevel: MobilityLevel;
  order: Scalars['Int']['output'];
};

export type GetAssignmentProgressHistoryPayload = {
  __typename?: 'GetAssignmentProgressHistoryPayload';
  items: Array<AssignmentProgressHistory>;
};

export type AssignmentProgressHistory = {
  __typename?: 'AssignmentProgressHistory';
  duration: Scalars['Int']['output'];
  timestamp: Scalars['Int']['output'];
};

export type AssignmentProgressReportsPayload = {
  __typename?: 'AssignmentProgressReportsPayload';
  items?: Maybe<Array<AssignmentProgressReport>>;
};

export type AssignmentProgressReport = {
  __typename?: 'AssignmentProgressReport';
  createdAt: Scalars['Long']['output'];
  durationSeconds: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Long']['output'];
  userId: Scalars['ID']['output'];
  weekId: Scalars['String']['output'];
  workoutId: Scalars['ID']['output'];
};

export enum LearningType {
  CourseLessonsTasks = 'COURSE_LESSONS_TASKS',
  Courses = 'COURSES',
  Questionnaires = 'QUESTIONNAIRES',
  SelfAssessmentQuestions = 'SELF_ASSESSMENT_QUESTIONS'
}

export type CourseLessonsTasks = {
  __typename?: 'CourseLessonsTasks';
  items: Array<CourseLessonTask>;
};

export type CourseLessonTask = {
  __typename?: 'CourseLessonTask';
  courseId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lessonContentKey: Scalars['String']['output'];
  lessonId: Scalars['String']['output'];
  lessonOrder: Scalars['Int']['output'];
  referenceItemContentKey: Scalars['String']['output'];
  referenceItemId: Scalars['String']['output'];
  referenceItemType: LearningReferenceItemType;
  taskContentKey: Scalars['String']['output'];
  taskId: Scalars['String']['output'];
  taskOrder: Scalars['Int']['output'];
};

export type Courses = {
  __typename?: 'Courses';
  items: Array<Course>;
};

export type Course = {
  __typename?: 'Course';
  caseStudyContentKey: Scalars['String']['output'];
  contentKey: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  relevanceOrder: Scalars['Int']['output'];
  version: Scalars['String']['output'];
};

export type LearningCourses = {
  __typename?: 'LearningCourses';
  items: Array<LearningCourse>;
};

export type LearningCourse = {
  __typename?: 'LearningCourse';
  contentKey: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  descriptionContentKey: Scalars['String']['output'];
  id: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  previewContentKey: Scalars['String']['output'];
  relevanceOrder: Scalars['Int']['output'];
  tasks?: Maybe<Array<LearningTask>>;
  version: Scalars['String']['output'];
};

export type LearningTask = {
  __typename?: 'LearningTask';
  courseId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  referenceItemContentKey: Scalars['String']['output'];
  referenceItemId: Scalars['String']['output'];
  referenceItemType: LearningReferenceItemType;
  taskContentKey: Scalars['String']['output'];
  taskId: Scalars['String']['output'];
  taskOrder: Scalars['Int']['output'];
};

export type LearningQuestionnaire = {
  __typename?: 'LearningQuestionnaire';
  contentKey: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  questions: Array<LearningQuestion>;
};

export type LearningQuestion = {
  __typename?: 'LearningQuestion';
  answerOptions: Array<LearningAnswerOption>;
  feedbackType: LearningQuestionFeedbackType;
  key: Scalars['String']['output'];
  questionOrder: Scalars['Int']['output'];
  questionType: LearningQuestionType;
};

export type LearningAnswerOption = {
  __typename?: 'LearningAnswerOption';
  answerOrder: Scalars['Int']['output'];
  isCorrect?: Maybe<Scalars['Boolean']['output']>;
  key: Scalars['String']['output'];
};

export enum LearningQuestionFeedbackType {
  Neutral = 'NEUTRAL',
  RightWrong = 'RIGHT_WRONG'
}

export enum LearningQuestionType {
  Multiple = 'MULTIPLE',
  Single = 'SINGLE'
}

export type LearningSelfAssessmentQuestions = {
  __typename?: 'LearningSelfAssessmentQuestions';
  items: Array<LearningSelfAssessmentQuestion>;
};

export type LearningSelfAssessmentQuestion = {
  __typename?: 'LearningSelfAssessmentQuestion';
  courses: Array<Scalars['String']['output']>;
  order: Scalars['Int']['output'];
  questionKey: Scalars['String']['output'];
};

export type Questionnaires = {
  __typename?: 'Questionnaires';
  items: Array<Questionnaire>;
};

export type Questionnaire = {
  __typename?: 'Questionnaire';
  contentKey: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  questions: Array<LearningQuestion>;
};

export type SelfAssessmentQuestions = {
  __typename?: 'SelfAssessmentQuestions';
  items: Array<SelfAssessmentQuestion>;
};

export type SelfAssessmentQuestion = {
  __typename?: 'SelfAssessmentQuestion';
  courses: Array<Scalars['String']['output']>;
  order: Scalars['Int']['output'];
  questionKey: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addAssignmentProgress: AssignmentProgressReport;
  addFavorite: Favorite;
  addLearningAssessmentResponse: LearningAssessmentResponse;
  addLearningProgress: LearningProgress;
  addLearningRecommendation: LearningRecommendation;
  addMoodTrackerCheckIn: MoodTrackerCheckIn;
  deleteFavorite: Scalars['Boolean']['output'];
  deleteUserData: Scalars['Boolean']['output'];
  generateWorkout: Workout;
  updateActiveCourse: ActiveCourse;
  updateLearningProgress: LearningProgress;
};


export type MutationAddAssignmentProgressArgs = {
  input: AddAssignmentProgressInput;
};


export type MutationAddFavoriteArgs = {
  itemKey: Scalars['String']['input'];
};


export type MutationAddLearningAssessmentResponseArgs = {
  learningAssessmentResponseInput: AddLearningAssessmentResponseInput;
};


export type MutationAddLearningProgressArgs = {
  learningProgressInput: AddLearningProgressInput;
};


export type MutationAddLearningRecommendationArgs = {
  learningRecommendationInput: AddLearningRecommendationInput;
};


export type MutationAddMoodTrackerCheckInArgs = {
  moodTrackerCheckInInput: AddMoodTrackerCheckInInput;
};


export type MutationDeleteFavoriteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUserDataArgs = {
  input?: InputMaybe<DeleteUserDataInput>;
};


export type MutationGenerateWorkoutArgs = {
  assessmentResponses: Array<GenerateWorkoutInput>;
};


export type MutationUpdateActiveCourseArgs = {
  activeCourseInput: UpdateActiveCourseInput;
};


export type MutationUpdateLearningProgressArgs = {
  learningProgressInput: UpdateLearningProgressInput;
};

export type AddAssignmentProgressInput = {
  assignmentId: Scalars['ID']['input'];
  durationSeconds: Scalars['Int']['input'];
  exerciseId: Scalars['ID']['input'];
  sessionId: Scalars['ID']['input'];
  workoutId: Scalars['ID']['input'];
};

export type AddLearningAssessmentResponseInput = {
  answers: Array<LearningAssessmentAnswerInput>;
};

export type LearningAssessmentAnswerInput = {
  questionKey: Scalars['String']['input'];
  score: Scalars['Int']['input'];
};

export type AddLearningProgressInput = {
  questionnaireResponse?: InputMaybe<QuestionnaireResponseInput>;
  referenceItemId: Scalars['String']['input'];
  referenceItemType: LearningReferenceItemType;
  taskId: Scalars['String']['input'];
};

export type QuestionnaireResponseInput = {
  answerKeys: Array<Scalars['String']['input']>;
  questionKey: Scalars['String']['input'];
};

export type AddLearningRecommendationInput = {
  answers: Array<LearningRecommendationAnswer>;
  responseId: Scalars['String']['input'];
};

export type LearningRecommendationAnswer = {
  questionKey: Scalars['String']['input'];
  score: Scalars['Int']['input'];
};

export type AddMoodTrackerCheckInInput = {
  feelings: Array<Scalars['String']['input']>;
  rating: Scalars['Int']['input'];
  reasons: Array<Scalars['String']['input']>;
};

export type DeleteUserDataInput = {
  assignmentProgressItems?: InputMaybe<Scalars['Boolean']['input']>;
  assignmentProgressReportsItems?: InputMaybe<Scalars['Boolean']['input']>;
  favoriteItems?: InputMaybe<Scalars['Boolean']['input']>;
  learningAssessmentResponseItems?: InputMaybe<Scalars['Boolean']['input']>;
  learningProgressItems?: InputMaybe<Scalars['Boolean']['input']>;
  learningRecommendationItems?: InputMaybe<Scalars['Boolean']['input']>;
  moodTrackerCheckInItems?: InputMaybe<Scalars['Boolean']['input']>;
  workoutItems?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GenerateWorkoutInput = {
  assessmentType: AssessmentType;
  mobilityLevel: MobilityLevel;
};

export type UpdateActiveCourseInput = {
  courseId: Scalars['String']['input'];
};

export type UpdateLearningProgressInput = {
  questionnaireResponse: QuestionnaireResponseInput;
  taskId: Scalars['String']['input'];
};

export type LearningAssessmentResponsesPayload = {
  __typename?: 'LearningAssessmentResponsesPayload';
  items: Array<LearningAssessmentResponse>;
};

export type LearningQuestionnaires = {
  __typename?: 'LearningQuestionnaires';
  items: Array<LearningQuestionnaire>;
};

export type LearningRecommendationsPayload = {
  __typename?: 'LearningRecommendationsPayload';
  items: Array<LearningRecommendation>;
};

export type WorkoutsPayload = {
  __typename?: 'WorkoutsPayload';
  items: Array<Workout>;
};

export type AssignmentProgressFragment = { __typename?: 'AssignmentProgress', assignmentId: string, createdAt: number, id: string, durationSeconds: number, exerciseId: string, sessionId: string, workoutId: string };

export type FavoriteFragment = { __typename?: 'Favorite', createdAt: number, id: string, itemKey: string, userId: string };

export type LearningAssessmentResponseFragment = { __typename?: 'LearningAssessmentResponse', id: string, userId: string, hpsScore: number, createdAt: number, validUntil: number, answers: Array<{ __typename?: 'LearningAssessmentAnswer', questionKey: string, score: number }> };

export type QuestionnaireResponsesFragment = { __typename?: 'QuestionnaireResponses', answerKeys: Array<string>, questionKey: string };

export type LearningProgressFragment = { __typename?: 'LearningProgress', taskId: string, referenceItemId: string, referenceItemType: LearningReferenceItemType, userId: string, createdAt: number, id: string, questionnaireResponses?: Array<{ __typename?: 'QuestionnaireResponses', answerKeys: Array<string>, questionKey: string } | null> | null };

export type LearningRecommendationFragment = { __typename?: 'LearningRecommendation', id: string, userId: string, responseId: string, recommendedCourseId: string, createdAt: number };

export type AssessmentResponseFragment = { __typename?: 'AssessmentResponse', assessmentType: AssessmentType, mobilityLevel: MobilityLevel };

export type AssignmentFragment = { __typename?: 'Assignment', id: string, assessmentType: AssessmentType, exerciseId: string, mobilityLevel: MobilityLevel, order: number };

export type WorkoutFragment = { __typename?: 'Workout', id: string, createdAt: number, validUntil: number, weaklyGoalSeconds: number, weekStart: number };

export type GetAssignmentProgressQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
  startTime: Scalars['Int']['input'];
}>;


export type GetAssignmentProgressQuery = { __typename?: 'Query', adminAssignmentProgress: { __typename?: 'QueryAssignmentProgressPayload', items: Array<{ __typename?: 'AssignmentProgress', assignmentId: string, createdAt: number, id: string, durationSeconds: number, exerciseId: string, sessionId: string, workoutId: string }> } };

export type GetFavoritesQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type GetFavoritesQuery = { __typename?: 'Query', adminFavorites: { __typename?: 'FavoritesPayload', items: Array<{ __typename?: 'Favorite', createdAt: number, id: string, itemKey: string, userId: string }> } };

export type GetLearningAssessmentResponseQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type GetLearningAssessmentResponseQuery = { __typename?: 'Query', adminLearningAssessmentResponse?: { __typename?: 'LearningAssessmentResponse', id: string, userId: string, hpsScore: number, createdAt: number, validUntil: number, answers: Array<{ __typename?: 'LearningAssessmentAnswer', questionKey: string, score: number }> } | null };

export type GetAllLearningProgressQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type GetAllLearningProgressQuery = { __typename?: 'Query', adminAllLearningProgress: { __typename?: 'LearningProgressPayload', items: Array<{ __typename?: 'LearningProgress', taskId: string, referenceItemId: string, referenceItemType: LearningReferenceItemType, userId: string, createdAt: number, id: string, questionnaireResponses?: Array<{ __typename?: 'QuestionnaireResponses', answerKeys: Array<string>, questionKey: string } | null> | null } | null> } };

export type GetLearningRecommendationQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type GetLearningRecommendationQuery = { __typename?: 'Query', adminLearningRecommendation?: { __typename?: 'LearningRecommendation', id: string, userId: string, responseId: string, recommendedCourseId: string, createdAt: number } | null };

export type GetWorkoutQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type GetWorkoutQuery = { __typename?: 'Query', adminWorkout?: { __typename?: 'Workout', id: string, createdAt: number, validUntil: number, weaklyGoalSeconds: number, weekStart: number, assignments: Array<{ __typename?: 'Assignment', id: string, assessmentType: AssessmentType, exerciseId: string, mobilityLevel: MobilityLevel, order: number }>, assessmentResponses: Array<{ __typename?: 'AssessmentResponse', assessmentType: AssessmentType, mobilityLevel: MobilityLevel }> } | null };

export const AssignmentProgressFragmentDoc = gql`
    fragment AssignmentProgress on AssignmentProgress {
  assignmentId
  createdAt
  id
  durationSeconds
  exerciseId
  sessionId
  workoutId
}
    `;
export const FavoriteFragmentDoc = gql`
    fragment Favorite on Favorite {
  createdAt
  id
  itemKey
  userId
}
    `;
export const LearningAssessmentResponseFragmentDoc = gql`
    fragment LearningAssessmentResponse on LearningAssessmentResponse {
  id
  userId
  hpsScore
  createdAt
  validUntil
  answers {
    questionKey
    score
  }
}
    `;
export const QuestionnaireResponsesFragmentDoc = gql`
    fragment QuestionnaireResponses on QuestionnaireResponses {
  answerKeys
  questionKey
}
    `;
export const LearningProgressFragmentDoc = gql`
    fragment LearningProgress on LearningProgress {
  taskId
  questionnaireResponses {
    answerKeys
    questionKey
  }
  referenceItemId
  referenceItemType
  userId
  createdAt
  id
}
    `;
export const LearningRecommendationFragmentDoc = gql`
    fragment LearningRecommendation on LearningRecommendation {
  id
  userId
  responseId
  recommendedCourseId
  createdAt
}
    `;
export const AssessmentResponseFragmentDoc = gql`
    fragment AssessmentResponse on AssessmentResponse {
  assessmentType
  mobilityLevel
}
    `;
export const AssignmentFragmentDoc = gql`
    fragment Assignment on Assignment {
  id
  assessmentType
  exerciseId
  mobilityLevel
  order
}
    `;
export const WorkoutFragmentDoc = gql`
    fragment Workout on Workout {
  id
  createdAt
  validUntil
  weaklyGoalSeconds
  weekStart
}
    `;
export const GetAssignmentProgressDocument = gql`
    query GetAssignmentProgress($userId: ID!, $startTime: Int!) {
  adminAssignmentProgress(userId: $userId, startTime: $startTime) {
    items {
      ...AssignmentProgress
    }
  }
}
    ${AssignmentProgressFragmentDoc}`;

/**
 * __useGetAssignmentProgressQuery__
 *
 * To run a query within a React component, call `useGetAssignmentProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentProgressQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      startTime: // value for 'startTime'
 *   },
 * });
 */
export function useGetAssignmentProgressQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetAssignmentProgressQuery, GetAssignmentProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAssignmentProgressQuery, GetAssignmentProgressQueryVariables>(GetAssignmentProgressDocument, options);
      }
export function useGetAssignmentProgressLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssignmentProgressQuery, GetAssignmentProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAssignmentProgressQuery, GetAssignmentProgressQueryVariables>(GetAssignmentProgressDocument, options);
        }
export type GetAssignmentProgressQueryHookResult = ReturnType<typeof useGetAssignmentProgressQuery>;
export type GetAssignmentProgressLazyQueryHookResult = ReturnType<typeof useGetAssignmentProgressLazyQuery>;
export type GetAssignmentProgressQueryResult = ApolloReactCommon.QueryResult<GetAssignmentProgressQuery, GetAssignmentProgressQueryVariables>;
export const GetFavoritesDocument = gql`
    query GetFavorites($userId: ID!) {
  adminFavorites(userId: $userId) {
    items {
      ...Favorite
    }
  }
}
    ${FavoriteFragmentDoc}`;

/**
 * __useGetFavoritesQuery__
 *
 * To run a query within a React component, call `useGetFavoritesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoritesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoritesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetFavoritesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetFavoritesQuery, GetFavoritesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetFavoritesQuery, GetFavoritesQueryVariables>(GetFavoritesDocument, options);
      }
export function useGetFavoritesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFavoritesQuery, GetFavoritesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetFavoritesQuery, GetFavoritesQueryVariables>(GetFavoritesDocument, options);
        }
export type GetFavoritesQueryHookResult = ReturnType<typeof useGetFavoritesQuery>;
export type GetFavoritesLazyQueryHookResult = ReturnType<typeof useGetFavoritesLazyQuery>;
export type GetFavoritesQueryResult = ApolloReactCommon.QueryResult<GetFavoritesQuery, GetFavoritesQueryVariables>;
export const GetLearningAssessmentResponseDocument = gql`
    query GetLearningAssessmentResponse($userId: ID!) {
  adminLearningAssessmentResponse(userId: $userId) {
    ...LearningAssessmentResponse
  }
}
    ${LearningAssessmentResponseFragmentDoc}`;

/**
 * __useGetLearningAssessmentResponseQuery__
 *
 * To run a query within a React component, call `useGetLearningAssessmentResponseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLearningAssessmentResponseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearningAssessmentResponseQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetLearningAssessmentResponseQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetLearningAssessmentResponseQuery, GetLearningAssessmentResponseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetLearningAssessmentResponseQuery, GetLearningAssessmentResponseQueryVariables>(GetLearningAssessmentResponseDocument, options);
      }
export function useGetLearningAssessmentResponseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLearningAssessmentResponseQuery, GetLearningAssessmentResponseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetLearningAssessmentResponseQuery, GetLearningAssessmentResponseQueryVariables>(GetLearningAssessmentResponseDocument, options);
        }
export type GetLearningAssessmentResponseQueryHookResult = ReturnType<typeof useGetLearningAssessmentResponseQuery>;
export type GetLearningAssessmentResponseLazyQueryHookResult = ReturnType<typeof useGetLearningAssessmentResponseLazyQuery>;
export type GetLearningAssessmentResponseQueryResult = ApolloReactCommon.QueryResult<GetLearningAssessmentResponseQuery, GetLearningAssessmentResponseQueryVariables>;
export const GetAllLearningProgressDocument = gql`
    query GetAllLearningProgress($userId: ID!) {
  adminAllLearningProgress(userId: $userId) {
    items {
      ...LearningProgress
    }
  }
}
    ${LearningProgressFragmentDoc}`;

/**
 * __useGetAllLearningProgressQuery__
 *
 * To run a query within a React component, call `useGetAllLearningProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLearningProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllLearningProgressQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAllLearningProgressQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetAllLearningProgressQuery, GetAllLearningProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAllLearningProgressQuery, GetAllLearningProgressQueryVariables>(GetAllLearningProgressDocument, options);
      }
export function useGetAllLearningProgressLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllLearningProgressQuery, GetAllLearningProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAllLearningProgressQuery, GetAllLearningProgressQueryVariables>(GetAllLearningProgressDocument, options);
        }
export type GetAllLearningProgressQueryHookResult = ReturnType<typeof useGetAllLearningProgressQuery>;
export type GetAllLearningProgressLazyQueryHookResult = ReturnType<typeof useGetAllLearningProgressLazyQuery>;
export type GetAllLearningProgressQueryResult = ApolloReactCommon.QueryResult<GetAllLearningProgressQuery, GetAllLearningProgressQueryVariables>;
export const GetLearningRecommendationDocument = gql`
    query GetLearningRecommendation($userId: ID!) {
  adminLearningRecommendation(userId: $userId) {
    ...LearningRecommendation
  }
}
    ${LearningRecommendationFragmentDoc}`;

/**
 * __useGetLearningRecommendationQuery__
 *
 * To run a query within a React component, call `useGetLearningRecommendationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLearningRecommendationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearningRecommendationQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetLearningRecommendationQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetLearningRecommendationQuery, GetLearningRecommendationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetLearningRecommendationQuery, GetLearningRecommendationQueryVariables>(GetLearningRecommendationDocument, options);
      }
export function useGetLearningRecommendationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLearningRecommendationQuery, GetLearningRecommendationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetLearningRecommendationQuery, GetLearningRecommendationQueryVariables>(GetLearningRecommendationDocument, options);
        }
export type GetLearningRecommendationQueryHookResult = ReturnType<typeof useGetLearningRecommendationQuery>;
export type GetLearningRecommendationLazyQueryHookResult = ReturnType<typeof useGetLearningRecommendationLazyQuery>;
export type GetLearningRecommendationQueryResult = ApolloReactCommon.QueryResult<GetLearningRecommendationQuery, GetLearningRecommendationQueryVariables>;
export const GetWorkoutDocument = gql`
    query GetWorkout($userId: ID!) {
  adminWorkout(userId: $userId) {
    ...Workout
    assignments {
      ...Assignment
    }
    assessmentResponses {
      ...AssessmentResponse
    }
  }
}
    ${WorkoutFragmentDoc}
${AssignmentFragmentDoc}
${AssessmentResponseFragmentDoc}`;

/**
 * __useGetWorkoutQuery__
 *
 * To run a query within a React component, call `useGetWorkoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkoutQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetWorkoutQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetWorkoutQuery, GetWorkoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetWorkoutQuery, GetWorkoutQueryVariables>(GetWorkoutDocument, options);
      }
export function useGetWorkoutLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWorkoutQuery, GetWorkoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetWorkoutQuery, GetWorkoutQueryVariables>(GetWorkoutDocument, options);
        }
export type GetWorkoutQueryHookResult = ReturnType<typeof useGetWorkoutQuery>;
export type GetWorkoutLazyQueryHookResult = ReturnType<typeof useGetWorkoutLazyQuery>;
export type GetWorkoutQueryResult = ApolloReactCommon.QueryResult<GetWorkoutQuery, GetWorkoutQueryVariables>;