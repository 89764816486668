import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { Auth } from 'aws-amplify';

const { REACT_APP_APPSYNC_GRAPHQL_API_ENDPOINT, REACT_APP_AWS_REGION } = process.env;

const httpLink = createHttpLink({ uri: REACT_APP_APPSYNC_GRAPHQL_API_ENDPOINT });

const getAuthorization = async () => `Bearer ${await Auth.currentSession().then((session) => session.getIdToken().getJwtToken())}`;

const authLink = setContext(async (_, { headers }) => ({
  headers: {
    ...headers,
    Authorization: await getAuthorization(),
  },
}));

const link = ApolloLink.from([authLink, httpLink]);

export const appSyncClient = new AWSAppSyncClient(
  {
    url: REACT_APP_APPSYNC_GRAPHQL_API_ENDPOINT!,
    region: REACT_APP_AWS_REGION!,
    disableOffline: true,
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: getAuthorization,
    },
  },
  {
    link,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  },
);
