import React from 'react';
import { Box, Typography, BoxProps } from '@mui/material';

import logo from '../assets/images/lb_logo.png';

const APP_NAME = 'LifeBonus Family';

const Logo: React.FC<BoxProps> = (props) => (
  <Box {...props}>
    <Box sx={styles.wrapper}>
      <img src={logo} alt="Logo" style={styles.image} />
      <Typography sx={styles.text}>{APP_NAME}</Typography>
    </Box>
  </Box>
);

const styles: Record<string, React.CSSProperties> = {
  wrapper: { display: 'flex', height: 40 },
  image: { marginTop: 6, marginBottom: 6, marginRight: 12 },
  text: {
    whiteSpace: 'nowrap',
    alignSelf: 'center',
    fontWeight: 500,
    fontSize: 20,
  },
};

export default Logo;
