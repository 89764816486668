import { DataProvider } from 'react-admin';
import { Amplify } from 'aws-amplify';
import {
  AdminGetUserCommand,
  AdminListGroupsForUserCommand,
  ListUsersCommand,
  CognitoIdentityProviderClient,
  UserType,
  GroupType,
} from '@aws-sdk/client-cognito-identity-provider';
import { User, UserAttributes } from '../../types';

const mapGroup = (group: GroupType): User['groups'][0] => ({
  name: group.Description || group.GroupName || '-',
  createdAt: group.CreationDate,
});

const mapUser = (user: UserType & { groups?: GroupType[] }): Partial<User> => {
  const attributes = (user.Attributes ?? []).reduce(
    (current, { Name, Value }) => ({
      ...current,
      [Name as string]: Value,
    }),
    {} as UserAttributes,
  );

  return {
    username: user.Username,
    status: user.UserStatus,
    createdAt: user.UserCreateDate,
    updatedAt: user.UserLastModifiedDate,
    enabled: user.Enabled,
    sub: attributes.sub,
    emailVerified: JSON.parse(attributes.email_verified) ?? false,
    name: attributes.name,
    email: attributes.email,
    userId: attributes['custom:userid'],
    id: user.Username,
    groups: user.groups?.map(mapGroup) ?? [],
  };
};

// pages starts with 1
const paginationTokens: Record<number, string | undefined> = {
  1: undefined,
};

// @ts-ignore We do not need all provider methods for mocked provider ("Partial<T>" will cause a type error)
export const usersDataProvider: DataProvider<ResourceType.Users> = {
  getList: async (_, { pagination, filter }): Promise<any> => {
    const credentials = await Amplify.Auth.currentCredentials();
    const client = new CognitoIdentityProviderClient({
      region: process.env.REACT_APP_AWS_REGION,
      credentials,
    });

    const result = await client.send(
      new ListUsersCommand({
        UserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
        Filter: filter?.query ? `email ^= "${filter.query}"` : undefined,
        Limit: pagination.perPage,
        PaginationToken: paginationTokens[pagination.page],
      }),
    );

    if (!result) {
      return {
        data: [] as User[],
        pageInfo: {},
      };
    }

    paginationTokens[pagination.page + 1] = result.PaginationToken;

    const EMPTY_USERS: User[] = [];

    return {
      data: result.Users?.map(mapUser) ?? EMPTY_USERS,
      pageInfo: {
        hasNextPage: result.PaginationToken !== undefined,
      },
    };
  },
  getOne: async (_, { id }): Promise<any> => {
    try {
      const credentials = await Amplify.Auth.currentCredentials();
      const client = new CognitoIdentityProviderClient({
        region: process.env.REACT_APP_AWS_REGION,
        credentials,
      });

      const userResult = await client.send(
        new AdminGetUserCommand({
          UserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
          Username: id as string,
        }),
      );

      const userGroupResult = await client.send(
        new AdminListGroupsForUserCommand({
          UserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
          Username: id as string,
        }),
      );

      const groups = userGroupResult?.Groups ?? [];

      return {
        data: userResult && mapUser({ ...userResult, Attributes: userResult.UserAttributes, groups }),
      };
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
