export enum ResourceType {
  Home = 'home',
  Users = 'users',
  Favorites = 'favorites',
  LearningRecommendations = 'learning-recommendations',
  LearningProgress = 'learning-progress',
  LearningAssessmentResponses = 'learning-assessment-responses',
  Workouts = 'workouts',
  AssignmentProgress = 'assignment-progress',
}
