import { AuthProvider } from 'react-admin';
import { Auth } from 'aws-amplify';

import { hasAdminGroup, getUserGroups } from '../utils/user-groups';
import { NoAdminRightsError } from '../types';

export const authProvider: AuthProvider = {
  checkAuth: async () => {
    const token = await Auth.currentSession().then((session) => session.getAccessToken());
    return hasAdminGroup(token) ? Promise.resolve() : Promise.reject(new NoAdminRightsError());
  },

  // Each time the dataProvider returns an error, react-admin calls the authProvider.checkError() method.
  // If it returns a rejected promise, react-admin calls the authProvider.logout() method immediately.
  checkError: Promise.reject,

  login: async (user: unknown) => {
    const token = await Auth.userSession(user).then((session) => session.getAccessToken());
    return hasAdminGroup(token) ? Promise.resolve() : Promise.reject(new NoAdminRightsError());
  },

  logout: async () => Auth.signOut(),

  getPermissions: async () => {
    const token = await Auth.currentSession().then((session) => session.getAccessToken());
    return getUserGroups(token);
  },
};
