import { ResourceProps } from 'react-admin';
import FavoriteIcon from '@mui/icons-material/Favorite';

import list from './list';
import { ResourceType } from '../../types';

export const favorites: ResourceProps = {
  list,
  name: ResourceType.Favorites,
  options: { label: 'Favorites' },
  icon: FavoriteIcon,
};
