import * as React from 'react';
import { AppBar as RaAppBar, AppBarProps } from 'react-admin';
import { Box, Typography } from '@mui/material';

import Logo from './logo.component';
import { GroupType } from '../types';
import { PermissionGuard } from './permission-guard';

const AppBar: React.FC<AppBarProps> = (props) => (
  <RaAppBar {...props}>
    <Box flex={1} flexDirection="row" display="flex" alignItems="center" justifyContent="space-between">
      <Logo />
      {/* dummy way of displaying groups to prove PermissionGuard component */}
      <Box flexDirection="row" display="flex" alignItems="center" justifyContent="flex-end" gap="12px">
        <Typography variant="subtitle2">Groups:</Typography>
        <PermissionGuard group={GroupType.Employee}>
          <Typography variant="subtitle1">Employee</Typography>
        </PermissionGuard>
        <PermissionGuard group={GroupType.TechEmployee}>
          <Typography variant="subtitle1">Tech employee</Typography>
        </PermissionGuard>
        <PermissionGuard group={GroupType.Admin}>
          <Typography variant="subtitle1">Admin</Typography>
        </PermissionGuard>
      </Box>
    </Box>
  </RaAppBar>
);

export default AppBar;
