import { Grid } from '@mui/material';
import { SimpleShowLayout, ArrayField, Datagrid, TextField, DateField } from 'react-admin';
import { User } from '../../../types';

export const UserGroups = (user: unknown) => {
  if (!(user as User)?.groups?.length) return null;

  return (
    <Grid item xs={12}>
      <SimpleShowLayout>
        <ArrayField source="groups">
          <Datagrid bulkActionButtons={false} sx={{ maxWidth: '800px' }}>
            <TextField source="name" sortable={false} />
            <DateField source="createdAt" label="Added at" sortable={false} showTime />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Grid>
  );
};
