import React from 'react';
import { Admin, Resource } from 'react-admin';
import { Route } from 'react-router-dom';

import './index.css';
import { authProvider, dataProvider } from './providers';
import { home, users } from './resources';
import FavoritesList from './resources/favorites/list';
import Login from './components/login.component';

import { theme } from './theme';
import Layout from './components/layout.component';

const App: React.FC = () => (
  <Admin dataProvider={dataProvider} authProvider={authProvider} theme={theme} loginPage={Login} layout={Layout} requireAuth>
    <Resource {...home} />
    <Resource {...users}>
      <Route path=":userId/favorites" element={<FavoritesList />} />
    </Resource>
  </Admin>
);

export default App;
