import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';

export interface LoginFormProps {
  onSubmit: (email: string, password: string) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const onEmailChange: React.ChangeEventHandler<HTMLInputElement> = (e) => setEmail(e.target.value);

  const onPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (e) => setPassword(e.target.value);

  const onSubmitClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    onSubmit(email, password);
  };

  return (
    <>
      <TextField label="Email" size="small" type="email" value={email} onChange={onEmailChange} />
      <TextField label="Password" size="small" type="password" value={password} onChange={onPasswordChange} />
      <Button variant="contained" onClick={onSubmitClick}>
        Sign In
      </Button>
    </>
  );
};

export default LoginForm;
