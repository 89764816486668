import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ArrayField, ChipField, Datagrid, DateField, List as RAList, SortPayload, TextField } from 'react-admin';
import { Card } from '@mui/material';
import { ResourceType } from '../../types';
import EmptyList from '../../components/empty-list.component';

const Empty = () => (
  <Card style={{ width: '100%' }}>
    <EmptyList text="No learning progress for this user" />
  </Card>
);

const List: React.FC<{ userId?: string }> = ({ userId: propsUserId }) => {
  const { userId } = useParams();
  const [sort, setSort] = useState<SortPayload | undefined>();

  return (
    <RAList
      resource={ResourceType.LearningProgress}
      filter={{ userId: userId || propsUserId, sort }}
      exporter={false}
      empty={<Empty />}
      actions={false}
      pagination={false}
    >
      <Datagrid bulkActionButtons={false} style={{ overflowX: 'visible' }} setSort={setSort} sort={sort}>
        <TextField source="id" />
        <DateField source="createdAt" transform={(value: number) => new Date(Number(`${value}000`))} showTime />
        <TextField source="taskId" />
        <TextField source="referenceItemType" />
        <TextField source="referenceItemId" />

        <ArrayField source="questionnaireResponses" sortable={false}>
          <Datagrid bulkActionButtons={false}>
            <TextField source="questionKey" />
            <ChipField source="answerKeys" />
          </Datagrid>
        </ArrayField>
      </Datagrid>
    </RAList>
  );
};

export default List;
