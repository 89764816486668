import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Datagrid, DateField, NumberField, List as RAList, SortPayload, TextField } from 'react-admin';
import { Card } from '@mui/material';
import { ResourceType } from '../../types';
import EmptyList from '../../components/empty-list.component';

const Empty = () => (
  <Card style={{ width: '100%' }}>
    <EmptyList text="No assignment progress for this user" />
  </Card>
);

const List: React.FC<{ userId?: string }> = ({ userId: propsUserId }) => {
  const { userId } = useParams();
  const [sort, setSort] = useState<SortPayload | undefined>();

  return (
    <RAList
      resource={ResourceType.AssignmentProgress}
      filter={{ userId: userId || propsUserId, sort }}
      exporter={false}
      empty={<Empty />}
      actions={false}
      pagination={false}
    >
      <Datagrid bulkActionButtons={false} style={{ overflowX: 'visible' }} setSort={setSort} sort={sort}>
        <TextField source="id" />
        <DateField source="createdAt" transform={(value: number) => new Date(Number(`${value}000`))} showTime />
        <TextField source="exerciseId" />
        <NumberField source="durationSeconds" />
        <TextField source="sessionId" />
        <TextField source="workoutId" />
        <TextField source="assignmentId" />
      </Datagrid>
    </RAList>
  );
};

export default List;
