import { Box, Typography } from '@mui/material';

const EmptyList = ({ text }: { text: string }) => (
  <Box textAlign="center" m={1}>
    <Typography variant="body1" paragraph>
      {text}
    </Typography>
  </Box>
);

export default EmptyList;
