import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ShowProps,
  BooleanField,
  WithRecord,
  useGetOne,
  TabbedShowLayout,
  FunctionField,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import FavoritesList from '../favorites/list';
import AssignmentProgressList from '../assignment-progress/list';
import LearningProgressList from '../learning-progress/list';
import { User, ResourceType, GroupType } from '../../types';
import { UserGroups, LearningAssessmentResponse, LearningRecommendation, Workout } from './nested-records';
import { FieldWithPermissions } from '../../components/permission-guard';

const UserShow: React.FC<ShowProps<User>> = (props) => {
  const { id: userId } = useParams();
  const { data: learningAssessmentResponseData } = useGetOne(ResourceType.LearningAssessmentResponses, { id: userId });
  const { data: learningRecommendationData } = useGetOne(ResourceType.LearningRecommendations, { id: userId });
  const { data: workout } = useGetOne(ResourceType.Workouts, { id: userId });

  return (
    <>
      <Show {...props}>
        <FunctionField
          render={(record: User) => (
            <Typography sx={{ margin: '16px 12px' }} variant="h5">
              {record.email}
            </Typography>
          )}
        />

        <TabbedShowLayout>
          <TabbedShowLayout.Tab label="User">
            <Grid container spacing={2}>
              <Grid item sm={12} md={6}>
                <SimpleShowLayout>
                  <TextField source="userId" label="User id" />
                  <TextField source="sub" />
                  <TextField source="username" />
                  <TextField source="name" emptyText="-" />
                  <FieldWithPermissions source="email" group={GroupType.TechEmployee}>
                    <TextField source="email" />
                  </FieldWithPermissions>
                </SimpleShowLayout>
              </Grid>
              <Grid item sm={12} md={6}>
                <SimpleShowLayout>
                  <TextField source="status" />
                  <DateField source="createdAt" showTime />
                  <DateField source="updatedAt" showTime />
                  <BooleanField source="enabled" />
                  <BooleanField source="emailVerified" />
                </SimpleShowLayout>
              </Grid>
            </Grid>

            <WithRecord render={UserGroups} />
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="Favorites">
            <FavoritesList userId={userId} />
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="Learning">
            <Grid container spacing={2}>
              <Grid item sm={12} md={6}>
                <Typography variant="subtitle2" style={{ marginBottom: '12px' }}>
                  Learning assessment response
                </Typography>
                <LearningAssessmentResponse record={learningAssessmentResponseData} />
              </Grid>

              <Grid item sm={12} md={6}>
                <Typography variant="subtitle2" style={{ marginBottom: '12px' }}>
                  Learning recommendation
                </Typography>
                <LearningRecommendation record={learningRecommendationData} />
              </Grid>
            </Grid>

            <Typography sx={{ marginTop: '16px' }} variant="subtitle2">
              Learning Progress
            </Typography>
            <LearningProgressList userId={userId} />
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="Workout">
            <Workout record={workout} />
            <Typography sx={{ marginTop: '16px' }} variant="subtitle2">
              Workout Progress
            </Typography>
            <AssignmentProgressList userId={userId} />
          </TabbedShowLayout.Tab>
        </TabbedShowLayout>
      </Show>

      <div style={{ marginBottom: '48px' }} />
    </>
  );
};

export default UserShow;
