import { ResourceProps } from 'react-admin';
import HomeIcon from '@mui/icons-material/Home';

import list from './list';
import { ResourceType } from '../../types';

export const home: ResourceProps = {
  list,
  name: ResourceType.Home,
  options: { label: 'Home' },
  icon: HomeIcon,
};
