import React, { PropsWithChildren, ReactElement } from 'react';
import { TextField, usePermissions } from 'react-admin';
import { GroupType, Groups } from '../types';

type PermissionGuardProps = {
  group: GroupType;
  NoAccess?: ReactElement;
};

export const PermissionGuard: React.FC<PropsWithChildren<PermissionGuardProps>> = ({ children, group, NoAccess = null }) => {
  const { isLoading, permissions } = usePermissions<Groups>();
  const hasAccess = permissions[group as GroupType];
  if (isLoading) return null;

  if (!hasAccess) {
    return NoAccess;
  }

  return <>{children}</>;
};

type FieldWithPermissionsProps = {
  group: GroupType;
  NoAccess?: React.ReactElement;
  // eslint-disable-next-line react/no-unused-prop-types
  source: string;
};

export const FieldWithPermissions: React.FC<React.PropsWithChildren<FieldWithPermissionsProps>> = ({
  group,
  children,
  NoAccess = <TextField sx={{ opacity: 0.4, fontWeight: 500 }} emptyText="No access" />,
}) => (
  <PermissionGuard group={group} NoAccess={NoAccess}>
    {children}
  </PermissionGuard>
);
