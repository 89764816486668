import React from 'react';
import {
  Datagrid,
  List as RAList,
  ListProps,
  TextField,
  BooleanField,
  FunctionField,
  Link,
  TextInput,
  Pagination as RaPagination,
  ExportButton,
  FilterButton,
  TopToolbar,
} from 'react-admin';
import { Link as MUILink } from '@mui/material';

import { User } from '../../types';

const Pagination = (props: any) => <RaPagination {...props} rowsPerPageOptions={[10, 20, 40, 60]} />;

const UserFilters = [<TextInput source="query" label="Search" alwaysOn />];

const List: React.FC<ListProps<User>> = (props) => {
  const Actions = (
    <TopToolbar>
      <FilterButton />
      <ExportButton maxResults={60} />
    </TopToolbar>
  );

  return (
    <RAList {...props} filters={UserFilters} pagination={<Pagination />} actions={Actions}>
      <Datagrid bulkActionButtons={false}>
        <FunctionField label="Id" render={(record: User) => <Link to={`${record.id}/show`}>{record.userId}</Link>} sortable={false} />
        <FunctionField
          label="email"
          render={(record: User) => {
            const providerSuffix = record.username?.includes('apple') ? ' - apple' : record.username?.includes('google') ? ' - google' : '';
            return (
              <>
                <MUILink href={`mailto:${record.email}`}>{record.email}</MUILink>
                {providerSuffix}
              </>
            );
          }}
          sortable={false}
        />
        <TextField source="name" sortable={false} />
        <BooleanField source="enabled" sortable={false} />
        <BooleanField source="emailVerified" sortable={false} />
        <FunctionField
          label="Favorites"
          render={(record: User) => <Link to={`${record.userId}/favorites`}>User favorites</Link>}
          sortable={false}
        />
      </Datagrid>
    </RAList>
  );
};

export default List;
