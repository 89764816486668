import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';

export interface NewPasswordFormProps {
  onSubmit: (newPassword: string) => void;
}

const NewPasswordForm: React.FC<NewPasswordFormProps> = ({ onSubmit }) => {
  const [password, setPassword] = useState<string>('');

  const onPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (e) => setPassword(e.target.value);

  const onSubmitClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    onSubmit(password);
  };

  return (
    <>
      <TextField label="New Password" size="small" type="password" value={password} onChange={onPasswordChange} />
      <Button variant="contained" onClick={onSubmitClick}>
        Confirm new password
      </Button>
    </>
  );
};

export default NewPasswordForm;
